import styled from "styled-components";

export const StyledContainerWrapper = styled.div`
  width: 100%;
  background: #202124; /* Background color */
  display: flex;
  height: 100vh;
  padding-top: 10px;

`;

export const StyledContainerProfile = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

`;
